import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import icono_calendar from "../../assets/icon/Calendar.svg";
import icono_odometro from "../../assets/icon/Odometer.svg";
import icono_miituo from "../../assets/icon/miituo_pago.svg";
import logo_seguro_auto from "../../assets/seguro_auto_liverpool.png";
import Modal from "../../components/Modal";
import { useAuth } from "../../provider/AuthProvider";
import { usePoliza } from "../../provider/PolizaProvider";
import { useResumePago } from "../../provider/ResumePagoProvider";

// Datalayer Google Tag Manager
import { pushToDataLayer } from "../../lib/pushToDataLayer";

const PaginaAgradecimientoOdo = ({ textFooterLegal }) => {
  const { user } = useAuth();
  const { poliza, downloadPoliza } = usePoliza();
  const { resumePago } = useResumePago();
  const [fechaPagoLocal, setFechaPagoLocal] = useState('');
  const [fechaProximoOdometro, setFechaProximoOdometro] = useState('');

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();


  useEffect(() => {
    if (resumePago) {
      var opciones = { day: 'numeric', month: 'long', year: 'numeric' };
      // convertir fecha de pago
      var fechaPago = resumePago?.fecha_compra;
      const fechaLocal = new Date(fechaPago).toLocaleDateString('es-ES', opciones);
      setFechaPagoLocal(fechaLocal);
      var fechaOdometro = resumePago?.fecha_proximo_odometro;
      const fechaOdometroLocal = new Date(fechaOdometro).toLocaleDateString('es-ES', opciones);
      setFechaProximoOdometro(fechaOdometroLocal);
    }
  }, [resumePago])

  const handleDownloadPoliza = async () => {
    const loadingElement = document.getElementById('loading');
    loadingElement.classList.remove('hidden');
    await downloadPoliza(poliza.policy.id, user.authToken);
    loadingElement.classList.add('hidden');
    const eventParams = {
      event_category: 'Reporte mensual odometro',
      step: '6A Descargar poliza',
      click: 'Descargar poliza'
    }
    pushToDataLayer('clic_confirmacion_pago_descargar_poliza', eventParams)
  }

  return (
    <div>
      <div className="flex justify-center w-full py-5 lg:py-10  bg-liverpoolPink">
        <div className="">
          <p className="self-center text-lg font-medium  text-white">
            Liverpool pocket
          </p>
        </div>
      </div>

      <div className="space-y-6 container px-4 mt-5">
        <div className="">
          <div className="bg-white shadow px-2">
            <div className="grid grid-cols-2 space-x-2 md:space-x-6 py-6 gap-2 md:gap-0">
              <div className="flex col-span-1 md:col-span-1 items-center  justify-end">
                <img
                  // height={7}
                  // width={300}
                  src={logo_seguro_auto}
                  width={130} height={35} className="h-[35px] w-[130px]"
                  alt="Membresia"
                />
              </div>
              <div className="grid col-span-1 md:col-span-1 pt-0 md:pt-0 items-center  justify-center md:justify-start lg:w-8/12">
                <p className="text-liverpoolPink font-semibold text-base lg:text-xl">{user?.name}</p>
                <p className="text-liverpoolCarbon text-xs md:text-base  font-bold">Gracias por tu compra</p>
                <p className="text-liverpoolCarbon text-xs md:text-base  ">Agradecemos tu preferencia</p>
              </div>
            </div>

            <div className="container text-justify  md:text-center  py-2">
              <p className="text-carbon  text-xs md:text-sm mb-2">
                Tu recibo de pago mensual está listo, en unos minutos lo recibirás en tu correo electrónico{" "}
                <strong>
                  {user?.email}
                </strong> {" "}
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <div className="card">
            <div className="card-header">
              <p className="text-sm md:text-base text-liverpoolCarbon font-bold">Información importante</p>
            </div>
            <div className="space-y-4">
              <div className='grid lg:grid-cols-1 gap-y-2 gap-x-6 space-y-2'>
                <div className='flex text-left lg:justify-left items-start gap-4'>
                  <div>
                    <img
                      // height={7}
                      // width={300}
                      className="w-9"
                      src={icono_calendar}
                      alt="calendar"
                    />
                  </div>
                  <div className="max-w-[80%]">
                    <p className='text-sm md:text-base text-liverpoolLead font-bold '>
                      Próxima fecha para reportar tu odómetro
                    </p>
                    <p className='text-sm md:text-base text-liverpoolLead'>
                      {fechaProximoOdometro} antes de las 23:59 hrs.
                    </p>
                  </div>
                </div>
                <div className='flex text-left lg:justify-left items-start gap-4'>
                  <div className="">
                    <img
                      // height={7}
                      // width={300}
                      className="w-9 "
                      src={icono_odometro}
                      alt="calendar"
                    />
                  </div>
                  <div className="max-w-[80%]">
                    <p className='text-sm md:text-base text-liverpoolLead font-bold '>
                      No olvides reportar tu odómetro
                    </p>
                    <p className='text-sm md:text-base text-liverpoolLead'>
                      Recuerda enviar una foto de tu odómetro cada mes para saber los
                      kilómetros que recorriste y procedas a realizar el pago mensual correspondiente.
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <p className="text-sm md:text-base text-liverpoolLead ">
                  Ponemos a tu disposición un centro especializado de atención a clientes,
                  en caso de que tengas alguna duda comunícate al {' '}
                  <a className="font-bold cursor-pointer" href="tel:8002000019">800 200 0019</a>.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-2 text-sm md:text-base">
          <p className="text-liverpoolCarbon">Detalles de tu compra: <strong>{fechaPagoLocal}</strong></p>
          <p className="text-liverpoolCarbon">Tu Póliza No. {poliza.policy.policyNumber}</p>
        </div>
        <div className="space-y-2">
          <p className="text-carbon text-sm md:text-base font-bold">
            Información general de la póliza
          </p>
          <div className="card">
            <div className="flex justify-center ">
              <img
                // height={7}
                // width={300}
                className="w-24"
                src={icono_miituo}
                alt="logo_miituo"
              />
            </div>
            <div>
              <div className='text-xs md:text-base space-y-2 text-carbon '>
                <div className=' space-y-4'>
                  <div className=' grid grid-cols-3  items-center'>
                    <div className='col-span-2 md:col-span-2 justify-self-start'>
                      <p>Paquete:</p>
                    </div>
                    <div className='col-span-1 md:col-span-1 justify-self-end'>
                      <p className='xl:whitespace-nowrap text-right'>
                        {poliza.policy.coverage}
                      </p>
                    </div>
                  </div>

                  <div className=' grid grid-cols-3 items-center'>
                    <div className='col-span-2 justify-self-start'>
                      <p>Número de póliza:</p>
                    </div>
                    <div className='col-span-1 justify-self-end'>
                      <p>
                        {poliza.policy.policyNumber}
                      </p>
                    </div>
                  </div>

                  <div className=' grid grid-cols-3  items-center'>
                    <div className='col-span-2 justify-self-start'>
                      <p>Asegurado:</p>
                    </div>
                    <div className='col-span-1 justify-self-end'>
                      <p className='xl:whitespace-nowrap text-right'>
                        {poliza.client.name}{' '}{poliza.client.last_name}{' '}{poliza.client.m_last_name}
                      </p>
                    </div>
                  </div>
                  <div className=' grid grid-cols-3  items-center'>
                    <div className='col-span-2 justify-self-start'>
                      <p>Marca:</p>
                    </div>
                    <div className='col-span-1 justify-self-end'>
                      <p className='xl:whitespace-nowrap text-right'>
                        {poliza.vehicle.brand}
                      </p>
                    </div>
                  </div>
                  <div className=' grid grid-cols-3  items-center'>
                    <div className='col-span-2 justify-self-start'>
                      <p>Año:</p>
                    </div>
                    <div className='col-span-1 justify-self-end'>
                      <p className='xl:whitespace-nowrap text-right'>
                        {poliza.vehicle.model}
                      </p>
                    </div>
                  </div>
                  <div className=' grid grid-cols-3  items-center'>
                    <div className='col-span-2 justify-self-start'>
                      <p>Versión:</p>
                    </div>
                    <div className='col-span-1 justify-self-end'>
                      <p className='xl:whitespace-nowrap text-right'>
                        {poliza.vehicle.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="space-y-2">
          <p className="text-carbon text-sm md:text-base font-bold">
            Información de pago
          </p>
          <div className="card">
            <div>
              <div className='text-xs md:text-base space-y-2 text-carbon '>
                <div className=' space-y-4'>
                  <div className=' grid grid-cols-3  items-center'>
                    <div className='col-span-2 md:col-span-2 justify-self-start'>
                      <p>Forma de pago:</p>
                    </div>
                    <div className='col-span-1 md:col-span-1 justify-self-end'>
                      <p className='xl:whitespace-nowrap text-right'>
                        {resumePago.last_number_card.padStart(16, '*')}
                      </p>
                    </div>
                  </div>
                  <div className=' grid grid-cols-3  items-center'>
                    <div className='col-span-2 md:col-span-2 justify-self-start'>
                      <p>Pago por kilómetro recorrido al mes:</p>
                    </div>
                    <div className='col-span-1 md:col-span-1 justify-self-end'>
                      <p className='xl:whitespace-nowrap text-right'>
                        $ {resumePago.tarifa_km}
                      </p>
                    </div>
                  </div>
                  <div className=' grid grid-cols-3 items-center'>
                    <div className='col-span-2 justify-self-start'>
                      <p>Prima Neta:</p>
                    </div>
                    <div className='col-span-1 justify-self-end'>
                      <p>
                        $ {resumePago.prima_neta}
                      </p>
                    </div>
                  </div>

                  <div className=' grid grid-cols-3  items-center'>
                    <div className='col-span-2 justify-self-start'>
                      <p>IVA:</p>
                    </div>
                    <div className='col-span-1 justify-self-end'>
                      <p className='xl:whitespace-nowrap text-right'>
                        $ {resumePago.iva}
                      </p>
                    </div>
                  </div>
                  <div className='flex justify-between !mt-4'>
                    <p className='font-bold text-carbon'>Prima Total:</p>
                    <p className='font-bold text-red-500'>
                      $ {resumePago.amount}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button
            className="background-color-liverpool w-full items-center px-4 py-2 border border-transparent rounded-md text-white hover:bg-liverpoolHover focus:bg-liverpoolHover active:bg-liverpoolHover focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150 disabled:opacity-25"
            type="button"
            // disabled={!paymentDetails?.amount}
            onClick={() => {
              const eventParams = {
                event_category: 'Reporte mensual odometro',
                step: '6 Terminar',
                click: 'Terminar'
              }
              pushToDataLayer('clic_confirmacion_pago_terminar', eventParams)
              navigate('/dashboard')
            }}
          >
            Terminar
          </button>
          <button
            className="w-full mt-4 text-liverpoolPink rounded-md font-medium text-sm px-5 py-2.5 text-center bg-white border-liverpoolPink border-2"
            onClick={handleDownloadPoliza}
          >
            Descargar póliza
          </button>

        </div>
      </div>
      <div className="text-center pb-4 mt-16">
        <button className="link text-xs" onClick={(event) => {
          event.preventDefault()
          openModal()
        }}>
          Consulta Términos legales
        </button>
      </div>
      {isModalOpen && (
        <Modal className="text-center" description={textFooterLegal} closeModal={closeModal} />
      )}

    </div>
  );
};

export default PaginaAgradecimientoOdo;
